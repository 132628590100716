<template>
  <v-container class="fill-height" fluid>
    <v-row class="d-flex justify-center align-center text-center">
      <v-col cols="12" md="8">
        <v-card class="pa-6" elevation="2">
          <v-card-title class="headline mb-2">Community Risk Mitigation Map Coming Soon!</v-card-title>
          <v-card-subtitle>
            We're working on a mapping tool to help people flag things that need to be fixed, and to identify weaknesses in their community.
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            Help us map your community to find and address risks before they become bigger problems.
          </v-card-text>
          <v-card-actions class="justify-center">
            <a
              href="https://gofund.me/76143d29"
              target="_blank"
              style="text-decoration: underline; color: blue"
            >
              Support Youth Involvement In Community Mapping
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SplashPage',
};
</script>

<style scoped>
.fill-height {
  height: 100vh;
  background: url('@/assets/flagthis.png') center/cover no-repeat;
  background-size: cover;
  background-position: center;
}
.pa-6 {
    padding: 24px !important;
    max-width: 90vw;
}
.v-card-subtitle {
  white-space: normal;
}
.v-card-title{
  white-space: normal;
}

</style>
