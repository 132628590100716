import axios from "axios"

const api = axios.create({
  baseURL: "https://dev.strollopia.com/",
  headers: {
    "Content-Type": "application/json"
  },
})

export default api;
