import { createRouter, createWebHistory } from 'vue-router';
import MainApp from '@/views/MainApp.vue';
import MapsList from '@/views/MapsList.vue';
import ViewerApp from '@/views/ViewerApp.vue';
import PoiDetail from '@/views/PoiDetail.vue';
import SplashPage from './views/SplashPage.vue';

const routes = [
  {
    path: '/',
    name: 'SplashPage',
    component: SplashPage,
  },
  {
    path: '/main',
    name: 'MainApp',
    component: MainApp,
  },
  {
    path: '/maps',
    name: 'MapsList',
    component: MapsList,
  },
  {
    path: '/viewer/:id',
    name: 'ViewerApp',
    component: ViewerApp,
    props: true,
  },
  {
    path: '/detail/:id',
    name: 'PoiDetail',
    component: PoiDetail,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
