<template>
  <div class="maps-list-container">
    <h1>Available Maps</h1>
    <div v-if="store.loading">Loading...</div>
    <div v-if="store.error" class="error">{{ store.error }}</div>
    <ul v-if="!store.loading && !store.error">
      <li v-for="map in store.maps" :key="map.id" @click="navigateToViewer(map.id)">
        Map ID: {{ map.id }} -
        <template v-if="map.center_geom && map.center_geom.coordinates && map.center_geom.coordinates.length === 2">
          Location: ({{ map.center_geom.coordinates[1] }}, {{ map.center_geom.coordinates[0] }})
        </template>
        <template v-else>
          Location information not available
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import { useDataStore } from "@/stores/dataStore";
import { useRouter } from 'vue-router';
import api from "@/endpoints/api";

export default {
  name: 'MapsList',
  setup() {
    const store = useDataStore();
    const router = useRouter();
    return { store, router };
  },
  async created() {
    this.store.setLoading(true);
    this.store.setError(null);
    try {
      const response = await api.get(`/api/maps`, {
        params: {
          org_domain_name: this.store.orgUrl,
          filter: 'center_geom' 
        }
      });
      console.log("Maps API Response:", response.data);
      if (Array.isArray(response.data)) {
        this.store.setMaps(response.data);
        console.log("Parsed Maps List:", this.store.maps);
      } else {
        console.error("Unexpected response structure");
        this.store.setError("Failed to fetch maps. Unexpected response structure.");
      }
    } catch (error) {
      console.error("Failed to fetch maps:", error);
      this.store.setError("Failed to fetch maps. Please try again later.");
    } finally {
      this.store.setLoading(false);
    }
  },
  methods: {
    navigateToViewer(mapId) {
      this.router.push({ 
        name: 'ViewerApp', 
        params: { id: mapId },
        query: { org_domain_name: this.store.orgUrl } 
      });
    }
  }
};
</script>

<style>
.maps-list-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.error {
  color: red;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 10px;
  margin: 10px 0;
  background: #f1f2e8;
  border-radius: 5px;
  cursor: pointer;
}

li:hover {
  background: #e2e3d3;
}
</style>
