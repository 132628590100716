<template>
  <div class="modal-overlay" v-if="isVisible" @click.self="close">
    <div class="modal-content">
      <button class="close-button" @click="close">X</button>
      <div v-if="mainImage" class="image-container">
        <img :src="mainImage" alt="POI Image" class="poi-image" />
      </div>
      <div>
        <h3>{{ poi.properties.name }}</h3>
        <p><strong>Sub Categories:</strong> {{ getSubCategoryNames(poi.properties.sub_categories).join(', ') }}</p>
        <div class="content-blocks">
          <div v-for="(block, index) in poi.properties.content_block" :key="index" class="content-block">
            <div v-for="(content, contentIndex) in block.content_array" :key="contentIndex" class="content-card">
              <p><strong>Caption:</strong> {{ content.caption }}</p>
              <p><strong>Field Key:</strong> {{ content.field_key }}</p>
              <p><strong>Media Type:</strong> {{ content.media_type }}</p>
              <p><strong>Reactive:</strong> {{ content.reactive }}</p>
              <div v-if="content.media_file && index !== 0" class="additional-image-container">
                <img :src="content.media_file" alt="Additional POI Image" class="additional-poi-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useDataStore } from "@/stores/dataStore";
import { toRaw } from "@vue/reactivity";
import categoriesMixin from "../categories";
import { defineComponent } from "vue";
import { s3Download } from "@/fileDownload";

export default defineComponent({
  name: 'PoiDetail',
  mixins: [categoriesMixin],
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    poi: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const store = useDataStore();

    const getSubCategoryNames = (subCategoryIds) => {
      const rawSubCategoryMap = toRaw(store.subCategoryMap);
      const rawSubCategoryIds = toRaw(subCategoryIds);

      return rawSubCategoryIds.map(id => rawSubCategoryMap[id] || `Unknown Subcategory ID: ${id}`);
    };

    const close = () => {
      emit('close'); 
    };

    return { getSubCategoryNames, close, store };
  },
  data() {
    return {
      mainImage: null
    };
  },
  created() {
    this.fetchImages();
  },
  methods: {
    async fetchImages() {
      for (let block of this.poi.properties.content_block) {
        for (let content of block.content_array) {
          if (content.file_key) {
            try {
              const mediaFile = await s3Download(content.file_key);
              content.media_file = mediaFile;
              if (!this.mainImage) {
                this.mainImage = mediaFile;
              }
            } catch (err) {
              console.error("Failed to download image: ", err);
            }
          }
        }
      }
    }
  }
});
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.image-container {
  text-align: center;
  margin-bottom: 20px;
}

.poi-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.content-blocks {
  margin-top: 20px;
}

.content-block {
  margin-bottom: 20px;
}

.content-card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.content-card p {
  margin: 5px 0;
}

.additional-image-container {
  text-align: center;
  margin-top: 10px;
}

.additional-poi-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
</style>
