// categories.js
import api from "@/endpoints/api";
import { useDataStore } from "@/stores/dataStore";

export default {
  created() {
    this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      const store = useDataStore();
      const endpoint = `/api/categories/`;
      try {
        const response = await api.get(endpoint, {
          params: {
            org_domain_name: store.orgUrl,
            min: true 
          }
        });
        console.log("Categories Response:", response);
        if (response.data && typeof response.data === "object") {
          const subCategoryMap = {};
          const categories = Object.entries(response.data.categories).map(
            ([mainCategoryName, subCatsObj]) => {
              const subCategories = Object.entries(subCatsObj).map(
                ([subCatName, subCatId]) => {
                  subCategoryMap[subCatId] = subCatName;
                  return {
                    name: subCatName,
                    id: subCatId,
                  };
                }
              );
              return {
                name: mainCategoryName,
                id: mainCategoryName.toLowerCase().replace(/\s+/g, "_"),
                subCategories,
              };
            }
          );
          store.setCategories(categories);
          store.setSubCategoryMap(subCategoryMap);
          console.log("Categories:", categories);
          console.log("SubCategoryMap after set in store:", store.subCategoryMap);
        } else {
          console.error("Categories format is incorrect:", response.data);
          alert("Categories data is not in the expected format.");
        }
      } catch (error) {
        console.error("Failed to fetch categories:", error);
        alert("Failed to fetch categories.");
      }
    },
  },
};
