<template>
  <div class="viewer-container">
    <div class="header">
      <button class="map-title">Issues Of Map {{ $route.params.id }}</button>
    </div>
    <div id="map" class="map-container"></div>
    <div v-if="store.loading" class="loading-overlay">Loading...</div>
    <div v-if="store.error" class="error">{{ store.error }}</div>
    <PoiDetail 
      v-if="store.selectedPoi"
      :isVisible="store.isModalVisible" 
      :poi="store.selectedPoi" 
      @close="closeModal" 
    />
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import PoiDetail from "./PoiDetail.vue";
import { useDataStore } from "@/stores/dataStore";
import { useRouter, useRoute } from 'vue-router';
import api from "@/endpoints/api";

export default {
  name: 'ViewerApp',
  components: {
    PoiDetail
  },
  setup() {
    const store = useDataStore();
    const router = useRouter();
    const route = useRoute();
    return { store, router, route };
  },
  data() {
    return {
      map: null,
      markers: [],
    };
  },
  async created() {
    const mapId = this.route.params.id;
    const orgDomainName = this.route.query.org_domain_name;
    await this.fetchMapData(mapId, orgDomainName);
  },
  methods: {
    async fetchMapData(mapId, orgDomainName) {
      this.store.setLoading(true);
      this.store.setError(null);
      try {
        const response = await api.get(`/api/maps/${mapId}/`, {
          params: { org_domain_name: orgDomainName }
        });
        console.log('Response Data:', response.data);

        const mapData = response.data.map_json;
        console.log('mapData:', mapData);
        console.log('center:', mapData.center);

        if (mapData && mapData.center && mapData.center.coordinates) {
          this.store.setMaps(mapData);
          this.store.pois = mapData.pois || [];
          console.log("Parsed Map Data:", this.store.maps);

          this.initializeMap(mapData.center.coordinates);
          this.addMarkers(this.store.pois);

          this.store.setLoading(false);
        } else {
          console.error("Center geometry is missing or malformed");
          this.store.setError("Center geometry is missing or malformed.");
          this.store.setLoading(false);
        }
      } catch (error) {
        console.error("Failed to fetch items:", error);
        this.store.setError("Failed to fetch items. Please try again later.");
        this.store.setLoading(false);
      }
    },
    initializeMap(center) {
      this.map = L.map('map').setView([center[1], center[0]], 13);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(this.map);
    },
    addMarkers(pois) {
      pois.forEach(poi => {
        console.log('POI:', poi); 
        const latLng = this.getLatLng(poi.geometry);
        if (latLng) {
          console.log(`Adding marker at ${latLng}`);
          const marker = L.marker(latLng).addTo(this.map);
          marker.on('click', () => this.showModal(poi));
          this.markers.push(marker);
        } else {
          console.warn(`Invalid coordinates for POI: ${JSON.stringify(poi)}`);
        }
      });
    },
    getLatLng(geometry) {
      if (geometry && geometry.coordinates && geometry.coordinates.length === 2) {
        return [geometry.coordinates[1], geometry.coordinates[0]];
      }
      return null;
    },
    showModal(poi) {
      this.store.setSelectedPoi(poi);
      this.store.setModalVisible(true);
    },
    closeModal() {
      this.store.setModalVisible(false);
      this.store.setSelectedPoi(null);
    }
  }
};
</script>

<style>
html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.viewer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

.map-title {
  background-color: white;
  color: black;
  border: 2px solid black;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
}

.map-title:hover {
  background-color: #f0f0f0;
}

.map-container {
  flex-grow: 1;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  font-size: 24px;
}

.error {
  color: red;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
</style>
