import L from "leaflet";
import { useDataStore } from "@/stores/dataStore";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png"
});

export default {
  data() {
    return {
      map: null,
      markers: []
    };
  },
  mounted() {
    this.initializeMap();
  },
  watch: {
    pois: {
      handler() {
        this.updateMarkers();
      },
      deep: true,
    },
  },
  methods: {
    initializeMap(center = [45.0918, -64.3665], zoom = 13) {
      const mapId = "map";
      this.map = L.map(mapId).setView(center, zoom);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "© OpenStreetMap contributors",
      }).addTo(this.map);
      this.updateMarkers();
    },
    updateMarkers() {
      const store = useDataStore();
      this.markers.forEach((marker) => this.map.removeLayer(marker));
      this.markers = [];

      store.pois.forEach((poi) => {
        if (poi.latitude && poi.longitude) {
          const marker = L.marker([poi.latitude, poi.longitude], {
            draggable: true,
          }).addTo(this.map);
          marker.on("dragend", (event) => {
            const { lat, lng } = event.target.getLatLng();
            poi.latitude = lat;
            poi.longitude = lng;
          });
          this.markers.push(marker);
        }
      });
    },
    getLatLng(geometry) {
      if (geometry && geometry.coordinates && geometry.coordinates.length === 2) {
        return [geometry.coordinates[1], geometry.coordinates[0]];
      }
      return null;
    },
    addMarkers(pois) {
      pois.forEach(poi => {
        const latLng = this.getLatLng(poi.position_geom);
        if (latLng) {
          const marker = L.marker(latLng).addTo(this.map);
          marker.on('click', () => this.showModal(poi));
          this.markers.push(marker);
        }
      });
    },
    showModal(poi) {
      const store = useDataStore();
      store.setSelectedPoi(poi);
      store.setModalVisible(true);
    },
    addPoi(selectedCategory) {
      const store = useDataStore();
      store.addPoi({
        map_content: false,
        name: "",
        latitude: 45.0918,
        longitude: -64.3665,
        sub_categories: [selectedCategory],
        content_block: [],
        selectedCard: null,
        contentBlock: { itemDescription: "" }
      });
    },
    removePoi(index) {
      const store = useDataStore();
      if (store.pois.length > 1) {
        store.removePoi(index);
      }
    },
  },
};
