<template>
  <div class="main-container">
    <header class="header">
      <h1 class="title">Nova Scotia Risk Map</h1>
    </header>

    <form @submit.prevent="updateUserData" class="user-info-form">
      <label>Email (optional)</label>
      <input type="email" v-model="store.userSuppliedEmail" placeholder="Email (optional)" />
    </form>

    <section class="item-section">
      <h2>FLAG AN ISSUE</h2>

      <div id="map" class="map-container"></div>

      <div class="form-group">
        <label>Select Categories:</label>
        <select v-model="store.selectedCategory">
          <option disabled value="">category</option>
          <optgroup
            v-for="category in store.categories"
            :key="category.id"
            :label="category.name"
          >
            <option
              v-for="subCategory in category.subCategories"
              :key="subCategory.id"
              :value="subCategory.id"
            >
              {{ subCategory.name }}
            </option>
          </optgroup>
        </select>
      </div>

      <div class="form-group">
        <label>Add Image:</label>
        <input type="file" @change="onFileChange" />
      </div>

      <div class="form-group">
        <label>Details:</label>
        <textarea
          v-model="store.pois[0].contentBlock.itemDescription"
          placeholder="Description"
        ></textarea>
      </div>

      <button @click.prevent="submitCurrentItemAndAddNew" class="btn-submit">
        ADD ANOTHER
      </button>
      <button @click.prevent="submitAll" class="btn-submit">SUBMIT</button>
      <button @click="navigateToMapsList" class="btn-navigate">
        View Available Maps
      </button>

      <div v-if="submitting" class="spinner-modal">
        <div class="spinner-box">
          <div class="spinner"></div>
          <p>Submitting...</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/endpoints/api";
import mapMixin from "../map";
import categoriesMixin from "../categories";
import { s3Upload } from "@/fileUpload";
import { s3Download } from "@/fileDownload";
import { useDataStore } from "@/stores/dataStore";
import { useRouter } from 'vue-router';

export default {
  mixins: [mapMixin, categoriesMixin],
  setup() {
    const store = useDataStore();
    const router = useRouter();
    return { store, router };
  },
  data() {
    return {
      submitting: false
    };
  },
  created() {
    this.loadMapJson();
  },
  methods: {
    loadMapJson() {
      axios
        .get("/p1_postable.json")
        .then((response) => {
          this.store.setPostableData(response.data);
          this.store.setPayload({ ...this.store.payload, map_json: response.data });
        })
        .catch((error) => {
          console.error("Failed to load map JSON:", error);
        });
    },
    async submitAll() {
      this.submitting = true;
      if (this.store.selectedFile && !this.store.uploadID) {
        await this.uploadFile();
      }
      this.store.payload.map_json.pois = this.store.pois.map((poi) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [poi.longitude, poi.latitude],
          },
          properties: {
            map_content: poi.map_content,
            name: poi.name,
            sub_categories:
              poi.sub_categories.length > 0
                ? poi.sub_categories.map(Number)
                : [this.store.selectedCategory],
            content_block: [
              {
                language: "en",
                layout_card: 21,
                content_array: [
                  {
                    reactive: 0,
                    media_type: 17,
                    media_file: this.store.uploadID,
                    field_key: "i1",
                    caption: poi.contentBlock.itemDescription || "not given",
                  }
                ],
              },
            ],
          },
        };
      });

      const payload = {
        org_domain_name: this.store.payload.org_domain_name,
        org_key: this.store.payload.org_key,
        user_supplied_name: this.store.userSuppliedName.trim() || "sam",
        id_field: this.store.payload.id_field || 0,
        pass_key: this.store.payload.pass_key || "string",
        user_supplied_email:
          this.store.userSuppliedEmail.trim() || "user@example.com",
        map_json: {
          is_public: true,
          languages: ["en"],
          tags: this.store.tags
            .filter((tag) => tag.trim() !== "")
            .map((tag) => ({ name: tag.trim() })) || [{ name: "string" }],
          pois: this.store.payload.map_json.pois,
        },
      };

      console.log("Constructed Payload:", payload);

      try {
        const response = await api.post("/api/anon-map-create/", payload);
        console.log("Response:", response.data);
        alert("Data submitted successfully!");
      } catch (error) {
        console.error("Failed to submit data:", error);
        if (error.response) {
          console.error("Error data:", error.response.data);
          console.error("Error status:", error.response.status);
          console.error("Error headers:", error.response.headers);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
        alert("Failed to submit data. Check the console for more details.");
      } finally {
        this.submitting = false;
      }
    },
    updateUserData() {
      if (this.store.userSuppliedName.trim()) {
        this.store.payload.user_supplied_name = this.store.userSuppliedName.trim();
      }
      if (this.store.userSuppliedEmail.trim()) {
        this.store.payload.user_supplied_email = this.store.userSuppliedEmail.trim();
      }
    },
    onFileChange(event) {
      this.store.setSelectedFile(event.target.files[0]);
      this.store.setUploadID(null);
    },
    async uploadFile() {
      if (this.store.selectedFile) {
        try {
          const uploadID = await s3Upload(this.store.selectedFile);
          this.store.setUploadID(uploadID);
          alert("File uploaded successfully with ID: " + uploadID);
        } catch (err) {
          console.error("Failed to upload file: ", err);
          alert("Failed to upload file. Check console for details.");
        }
      } else {
        alert("Please select a file to upload.");
      }
    },
    async submitCurrentItemAndAddNew() {
      await this.uploadFile();
      this.addPoi(this.store.selectedCategory);
      this.submitAll();
    },
    async downloadFile() {
      try {
        const downloadURL = await s3Download(this.store.fileKey);
        window.open(downloadURL, "_blank");
      } catch (err) {
        console.error("Failed to download file: ", err);
        alert("Failed to download file. Check console for details.");
      }
    },
    addPoi(selectedCategory) {
  this.store.incrementPoiCounter();
  this.store.addPoi({
    map_content: false,
    name: `p${this.store.poiCounter}`,
    latitude: 45.0918,
    longitude: -64.3665,
    sub_categories: [selectedCategory],
    content_block: [], 
    selectedCard: null,
    contentBlock: { itemDescription: "" } 
  });
},
    navigateToMapsList() {
      this.router.push({ name: "MapsList" });
    },
  },
};
</script>

<style>
.main-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.header {
  text-align: right;
  margin-bottom: 20px;
}

.title {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.user-info-form {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.user-info-form label {
  display: none;
}

.user-info-form input {
  width: 60%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #d6dbce;
  font-size: 16px;
  font-style: italic;
}

.item-section {
  background-color: #f1f2e8;
  padding: 20px;
  border-radius: 5px;
}

.item-section h2 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.map-container {
  height: 300px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group select,
.form-group input[type="file"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #d6dbce;
  font-size: 16px;
  font-style: italic;
}

.form-group input[type="file"] {
  cursor: pointer;
}

.form-group textarea {
  height: 100px;
  resize: none;
}

.btn-submit {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: #555;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
}

.btn-submit:hover {
  background-color: #333;
}

.spinner-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner-box {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #333;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
