import { defineStore } from 'pinia';

export const useDataStore = defineStore('data', {
  state: () => ({
    orgUrl: "ns.flagthis.ca",
    payload: {
      org_domain_name: "ns.flagthis.ca",
      org_key: "abcd",
      name: "bob",
      id_field: null,
      pass_key: null,
      email: null,
      map_json: {},
    },
    tags: [""],
    map: null,
    markers: [],
    pois: [
      {
        map_content: false,
        name: "p1",
        latitude: 45.0918,
        longitude: -64.3665,
        sub_categories: [],
        content_block: [],
        selectedCard: null,
        contentBlock: { itemDescription: "" }
      },
    ],
    categories: [],
    subCategoryMap: {},
    postableData: null,
    userSuppliedName: "",
    userSuppliedEmail: "",
    selectedCategory: null,
    selectedFile: null,
    uploadID: null,
    fileKey: "example-file-key",
    poiCounter: 1,
    maps: [],
    loading: true,
    error: null,
    isModalVisible: false,
    selectedPoi: null,
  }),
  actions: {
    setPayload(payload) {
      this.payload = payload;
    },
    addTag(tag) {
      this.tags.push(tag);
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    addPoi(poi) {
      this.pois.push(poi);
    },
    removePoi(index) {
      this.pois.splice(index, 1);
    },
    setCategories(categories) {
      this.categories = categories;
    },
    setSubCategoryMap(map) {
      this.subCategoryMap = map;
    },
    setPostableData(data) {
      this.postableData = data;
    },
    setUserSuppliedName(name) {
      this.userSuppliedName = name;
    },
    setUserSuppliedEmail(email) {
      this.userSuppliedEmail = email;
    },
    setSelectedCategory(category) {
      this.selectedCategory = category;
    },
    setSelectedFile(file) {
      this.selectedFile = file;
    },
    setUploadID(id) {
      this.uploadID = id;
    },
    incrementPoiCounter() {
      this.poiCounter++;
    },
    setMaps(maps) {
      this.maps = maps;
    },
    setLoading(loading) {
      this.loading = loading;
    },
    setError(error) {
      this.error = error;
    },
    setModalVisible(isVisible) {
      this.isModalVisible = isVisible;
    },
    setSelectedPoi(poi) {
      this.selectedPoi = poi;
    },
  }
});
